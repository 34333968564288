import { Injectable, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { Document } from 'cheerio';
import { PlatformService } from './platform.service';

export interface GoogleTagManagerConfig {
  id: string | null;
  gtm_auth?: string;
  gtm_preview?: string;

  [key: string]: string | null | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private _platform = inject(PlatformService);
  private _window = inject<Window>(WINDOW);
  private _document = inject<Document>(DOCUMENT);


  private _isLoaded = false;
  public config: GoogleTagManagerConfig = { id: 'GTM-WDP3DQG' };

  public getDataLayer(): any[] {
    const window = this._window;
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }

  public addGtmToDom() {
    return new Promise((resolve, reject) => {
      if (this._isLoaded) {
        return resolve(this._isLoaded);
      }
      const doc: any = this._document;
      this._pushOnDataLayer({
        'gtm.start': new Date().getTime(),
        'event': 'gtm.js',
      });
      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this._applyGtmQueryParams(
        this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js',
      );
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
      if (this._platform.isBrowser) {
        gtmScript.addEventListener('load', () => {
          return resolve((this._isLoaded = true));
        });
        gtmScript.addEventListener('error', () => {
          return reject(false);
        });
      } else {
        return resolve((this._isLoaded = true));
      }
    });
  }


  private _pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private _applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter(k => this.config[k])
        .map(k => `${k}=${this.config[k]}`)
        .join('&')
    );
  }


  /**
   * Game click event
   * @param gameid
   */
  public gameClickEvent(gameid: string) {
    this._pushOnDataLayer({
      event: 'game_click',
      gameid,
    });
  }

  /**
   * Banner Click Event
   * @param gameid
   */
  public bannerClickEvent(bannerOrder: string) {
    this._pushOnDataLayer({
      event: 'click_on_main_banner',
      eventCategory: 'main_banner',
      eventLabel: 'event_label',
      eventValue: bannerOrder,
    });
  }

  /**
   * Popups event
   * @param modal
   */
  public popupEvent(popup: string) {
    this._pushOnDataLayer({
      event: '21bitpopup',
      event_action: `open-${popup}`,
      event_category: popup,
    });
  }
}
